import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
import { Button, ButtonTypeEnum } from '@uc-common/button';
import { Code } from '@uc-common/code';
import { SectionWrapper } from '~/src/components/CommonStyles';
import { Link } from "~/src/components/Link";
import { track } from "~/src/components/forStartGuide";
export const frontmatter = {
  title: 'Transfer your files to Uploadcare',
  description: 'If you came to Uploadcare from a DIY solution or another platform, the first thing to migrate is to copy files from their current place to your Uploadcare storage, so that they’re handled and delivered with an Uploadcare CDN.',
  header: 'How to transfer your files to Uploadcare'
};
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Paragraph = makeShortcode("Paragraph");
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Paragraph mdxType="Paragraph">
  If you came to Uploadcare from a DIY solution or another platform, the first
  thing to migrate is to copy files from their current place to your Uploadcare
  storage, so that they’re handled and delivered with an Uploadcare CDN.
    </Paragraph>
    <Paragraph mdxType="Paragraph">
  <SectionWrapper mdxType="SectionWrapper">
    <Button as={Link} to='/accounts/signup/' onClick={() => track('Sign up')} type={ButtonTypeEnum.PRIMARY} outline={false} ghost mdxType="Button">
        Get your API keys
    </Button>
  </SectionWrapper>
    </Paragraph>
    <Paragraph mdxType="Paragraph">
  Our <a href="https://github.com/uploadcare/migro" rel="noopener nofollow" target="_blank">Migro tool</a> will help you do that.
    </Paragraph>
    <Paragraph mdxType="Paragraph"><strong>Step 1.</strong> Create a text file with a list of files you’d like to transfer to Uploadcare:</Paragraph>
  <Code code={`
    https://cdn.filestackcontent.com/YBLVVdUpRqC4nOynxDd8\nhttps://www.facebook.com/rsrc.php/v3/y7/r/dTQOHZm7Z-3.svg\nuNWvPRXJQmO49MJbPZn9
  `} autoLinker={false} mdxType="Code" />
    <Paragraph mdxType="Paragraph"><strong>Step 2.</strong> Feed it to Migro to get the transfer status and a new path with a UUID at Uploadcare for each file:</Paragraph>
  <Code code={`https://cdn.filestackcontent.com/YBLVVdUpRqC4nOynxDd8  success  https://ucarecdn.com/d8f8de4b-f92e-41a0-b7f9-28fd4baad9ae/\nhttps://www.facebook.com/rsrc.php/v3/y7/r/dTQOHZm7Z-3.svg  success  https://ucarecdn.com/4a03f3d4-2bd3-456e-89a5-008190980248/\nhttps://cdn.filestackcontent.com/uNWvPRXJQmO49MJbPZn9  fail  Uploading of these files types is not allowed on your current plan.`} autoLinker={false} mdxType="Code" />
    <Paragraph mdxType="Paragraph"><strong>Step 3.</strong> After that, replace the old links with the new ones in your project.</Paragraph>
    <br />
    <Paragraph mdxType="Paragraph">
  <SectionWrapper mdxType="SectionWrapper">
    <Button as={Link} to='/accounts/signup/' onClick={() => track('Sign up')} type={ButtonTypeEnum.PRIMARY} outline={false} ghost mdxType="Button">
        Sign up to upload your first file
    </Button>
  </SectionWrapper>
    </Paragraph>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      